import {
  EthereumClient,
  modalConnectors,
  walletConnectProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet, goerli } from "wagmi/chains";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";

import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from "./pages/Home";
import Mint from "./pages/Mint";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Allowlist from "./pages/Allowlist";
import './App.css';

function App() {
  // const chains = [goerli];
  const chains = [mainnet];

  // Wagmi client
  const { provider } = configureChains(chains, [
    walletConnectProvider({ projectId: "d3724472f8ac075dd1049192a1961d7f" }),
  ]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({ appName: "web3Modal", chains }),
    provider,
  });

  // Web3Modal Ethereum Client
  const ethereumClient = new EthereumClient(wagmiClient, chains);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <Router>
          <Header/>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/mint" element={<Mint />} />
            <Route path="/allowlist" element={<Allowlist />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
          <Footer />
        </Router>
        
      </WagmiConfig>

      <Web3Modal
        projectId="d3724472f8ac075dd1049192a1961d7f"
        ethereumClient={ethereumClient}
      />
    </>
  );
}

export default App;
