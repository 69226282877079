export default function Footer(){
  return(
    <section id="footer">
      <div className="container">
        <div className="top-footer">
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="footer-box">
                <ul className="footer-social-icon">
                  <li>
                    <a href="https://discord.gg/dawgsday" target="_blank" title="Dawg's Day Discord" rel="noopener">
                      <img src="img/footer-icon-discord.png" alt="Discord icon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/dawgsdaynft" target="_blank" title="Dawg's Day Twitter" rel="noopener">
                      <img src="img/footer-icon-twitter.png" alt="Twitter icon" />
                    </a>
                  </li>
                  <li>
                    <a href="https://opensea.io/collection/dawgs-day" target="_blank" title="Dawg's Day collection on OpenSea" rel="noopener">
                      <img src="img/footer-icon-opensea.png" alt="OpenSea icon" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div className="footer-box">
                <div className="footer-logo">
                  <a href="/">
                    <img src="img/dawgs-day-logo-secondary.png" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-4 col-12">
              <div className="footer-box">

                <ul className="footer-menu">
                  <li><a href="/privacy-policy" title="Privacy Policy">Privacy Policy</a></li>
                  {/*<li><a href="javascript:void(0)">Terms of Service</a></li>*/}
                </ul>

              </div>
            </div>
          </div>
        </div>
        <div className="button-footer">
          <p className="text-center">Dawg's Day &copy; All Rights Reserved.</p>
        </div>
      </div>
    </section>
  )
}