import {useEffect, useRef, useState} from "react";
import { useWeb3Modal } from "@web3modal/react";
import { useAccount, useSigner } from "wagmi";
import { BigNumber, ethers, utils } from "ethers";
import { MerkleTree } from "merkletreejs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputGroup, Button, Form } from "react-bootstrap";
import { WinterCheckout } from '@usewinter/checkout';
import Helmet from "react-helmet";
import Countdown from 'react-countdown';

const { abi } = require("../data/Abi.json");
const SMART_CONTRACT_ADDRESS = "0xcE2Bd5E5D9f688E81C8b6770EA7cab988Ec82424";

export default function Mint(){
  const { isOpen, open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { data: signer } = useSigner();
  const [ mintPrice, setMintPrice ] = useState(0);
  const [ mintCount, setMintCount ] = useState(1);
  const [ correctNetwork, setCorrectNetwork ] = useState(false);
  const [ maxSupply, setMaxSupply ] = useState(0);
  const [ totalSupply, setTotalSupply ] = useState(0);
  const [showWinter, setShowWinter] = useState(false);

  const isMounted = useRef(true);
  const [state, setState] = useState({
    allowlistAddresses: [],
    allowlistAddressList: '',
    allowlistMerkleRoot: '',
    isLoading: false,
  });

  const contract = new ethers.Contract(
    SMART_CONTRACT_ADDRESS,
    abi,
    signer
  );

  const allowlistMintStart  = 1676142683000; // mainnet: 1676142683000
  const publicMintStart     = 1676149883000; // mainnet: 1676149883000

  window.addEventListener('message', (event) => {
    // console.log(`Received message: ${event.data}`);

    if (event.data === 'closeWinterCheckoutModal') {
      setShowWinter(false);
    }
  });

  const toggleWinter = async () => {
    setShowWinter(true)
  };

  /*useEffect(() => {
    const getAddresses = async () => {
      const allowlistResponse = await fetch("https://dawgs.day/api/allowlist/addresses");
      const allowlistObject = await allowlistResponse.json();
      const allowlistAddressList = Object.keys(allowlistObject).map(key =>
          <li key={key}>{allowlistObject[key]}</li>
      );
      const { keccak256 } = ethers.utils;
      let leaves = allowlistObject.map((addr) => keccak256(addr));
      const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });
      const merkleRootHash = merkleTree.getHexRoot();

      setState((prevState) => ({
        ...prevState,
        allowlistAddresses: allowlistObject,
        allowlistAddressList: allowlistAddressList,
        allowlistMerkleRoot: merkleRootHash
      }));
    };

    getAddresses();

    if (signer) {    
      if(signer.provider._network.name === "homestead"){
        setCorrectNetwork(true);
        getContract();
      }else{
        setCorrectNetwork(false);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, [signer]);*/

  async function getContract() {
    if (isConnected){
      try{       
        const price = await contract.getPrice();
        setMintPrice(ethers.utils.formatEther(price));

        const max_supply = await contract.MAX_SUPPLY();
        setMaxSupply(max_supply);

        const total_supply = await contract.totalSupply();
        setTotalSupply(Number(total_supply));
      }catch (err){
        console.log(err);
      }      
    }
  }

  async function handleMint() {
    if (isConnected) {
      try {
        const price = await contract.getPrice();

        const tx = await contract.mint(address, mintCount, {
          value: BigNumber.from(mintCount.toString()).mul(price),
        });
        const res = await tx.wait();
        // console.log(res);
        const total_supply = await contract.totalSupply();
        setTotalSupply(Number(total_supply));

        toast.success("Successfully minted!");        
      } catch (err) {
        console.log(err);
        alert(err);
        // toast(err);
      }
    }
  }

  async function handleAllowlistMint() {
    if (isConnected) {
      try {

        let allowlist = state.allowlistAddresses;
        // console.log(allowlist)

        let proof = ''

        const userWalletAddress = address;

        // console.log(`address: ${address}`);
        // console.log(`allowlist: ${JSON.stringify(allowlist)}`);

        if (userWalletAddress && Object.values(allowlist).includes(userWalletAddress)) {
          const { keccak256 } = ethers.utils
          let leaves = allowlist.map((addr) => keccak256(addr))
          const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
          let hashedAddress = keccak256(userWalletAddress)
          proof = merkleTree.getHexProof(hashedAddress)

          const price = await contract.getPrice();

          const tx = await contract.allowListMint(address, mintCount, proof, {
            value: BigNumber.from(mintCount.toString()).mul(price),
          });
          const res = await tx.wait();
          // console.log(res);
          const total_supply = await contract.totalSupply();
          setTotalSupply(Number(total_supply));

          toast.success("Successfully minted!");
        }else{
          toast(`${address} is not on the allow-list.`);
        }
      } catch (err) {
        console.log(err);
        alert(err);
        // toast(err);
      }
    }
  }

  function plusClick(){
    if(mintCount < 50){
      let count = mintCount;
      count ++;
      setMintCount(count);
    }
  }
  function minusClick(){
    if(mintCount > 1){
      let count = mintCount;
      count --;
      setMintCount(count);
    }
  }

  const allowlistCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <AllowlistMintSection />;
    } else {
      // Render a countdown
      return <><h5>Allowlist Mint starts<br/>Feb 11, 2023 at 2:11:23 PM US Eastern</h5><p>{days} days {hours}:{minutes}:{seconds}</p></>;
    }
  };

  const publicCountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <PublicMintSection />;
    } else {
      // Render a countdown
      return <><h5>Public Mint starts starts<br/>Feb 11, 2023 at 4:11:23 PM US Eastern:</h5><p>{days} days {hours}:{minutes}:{seconds}</p></>;
    }
  };

  const AllowlistMintSection = () => <>
    <WinterCheckout projectId={8776} showModal={showWinter} production={false} />
    {(isConnected && !correctNetwork) &&
        <p>Please switch to Ethereum network</p>
    }

    {(isConnected && correctNetwork) &&
        <>
          <p>{mintPrice * mintCount} ETH</p>
          <div className="center-block">
            <InputGroup className="mb-3">
              <Button
                  variant="primary"
                  id="button-reduce"
                  className="mint-qty-btn"
                  aria-label="Reduce mint quantity"
                  onClick={minusClick}
              >
                -
              </Button>

              <Form.Control
                  id="mint-quantity-input"
                  aria-label="Mint quantity"
                  aria-describedby="basic-addon1"
                  defaultValue={mintCount}
              />

              <Button
                  variant="primary"
                  id="button-add"
                  className="mint-qty-btn"
                  aria-label="Reduce mint quantity"
                  onClick={plusClick}
              >
                +
              </Button>
            </InputGroup>
          </div>
          <br/>
          <button className="mint-btn text-decoration-none mb-3" onClick={handleAllowlistMint}>
            Mint with ETH
          </button>
          <p>Total minted: {totalSupply} / {maxSupply}</p>
        </>
    }

    {!isConnected &&
        <>
          <button className="mint-btn text-decoration-none mb-4 mr-2" onClick={() => open()}>
            Connect Wallet
          </button>
        </>
    }
    <>

      <button
          className="mint-btn text-decoration-none"
          onClick={toggleWinter}
      >
        Use Credit Card
      </button>
    </>
  </>;

  const PublicMintSection = () => <>
    <WinterCheckout projectId={8642} showModal={showWinter} production={false} />

    {(isConnected && !correctNetwork) &&
        <p>Please switch to Ethereum network</p>
    }

    {(isConnected && correctNetwork) &&
        <>
          <p>{mintPrice * mintCount} ETH</p>
          <div className="center-block">
            <InputGroup className="mb-3">
              <Button
                  variant="primary"
                  id="button-reduce"
                  className="mint-qty-btn"
                  aria-label="Reduce mint quantity"
                  onClick={minusClick}
              >
                -
              </Button>

              <Form.Control
                  id="mint-quantity-input"
                  aria-label="Mint quantity"
                  aria-describedby="basic-addon1"
                  defaultValue={mintCount}
              />

              <Button
                  variant="primary"
                  id="button-add"
                  className="mint-qty-btn"
                  aria-label="Reduce mint quantity"
                  onClick={plusClick}
              >
                +
              </Button>
            </InputGroup>
          </div>
          <br/>
          <button className="mint-btn text-decoration-none mb-3" onClick={handleMint}>
            Mint with ETH
          </button>
          <p>Total minted: {totalSupply} / {maxSupply}</p>
        </>
    }

    {!isConnected &&
        <>
          <button className="mint-btn text-decoration-none mb-4 mr-2" onClick={() => open()}>
            Connect Wallet
          </button>
        </>
    }
    <>

      <button
          className="mint-btn text-decoration-none"
          onClick={toggleWinter}
      >
        Use Credit Card
      </button>
    </>
  </>;

  return(
      <>
        <Helmet>
          <title>Mint | Dawg's Day NFT</title>
          <meta name="description" content="Mint a Dawg's Day NFT! This generative art project aims to improve lives through a community centered around self-improvement and work-life balance." />

          <meta itemprop="name" content="Mint | Dawg's Day NFT" />
          <meta itemprop="description" content="Mint a Dawg's Day NFT! This generative art project aims to improve lives through a community centered around self-improvement and work-life balance." />
          <meta itemprop="image" content="https://dawgs.day/img/dawgs-day-og.png" />

          <meta property="og:url" content="http://dawgs.day/mint" />
          <meta property="og:title" content="Mint | Dawg's Day NFT" />
          <meta property="og:description" content="Mint a Dawg's Day NFT! This generative art project aims to improve lives through a community centered around self-improvement and work-life balance." />

          <meta name="twitter:title" content="Mint | Dawg's Day NFT" />
          <meta name="twitter:description" content="Mint a Dawg's Day NFT! This generative art project aims to improve lives through a community centered around self-improvement and work-life balance." />
        </Helmet>

        {/* Banner Section  */}
        <section className="compact-hero"></section>

        <section className="about-sec" id="about">
          <div className="col-md-6 mt-3 m-auto text-center">

            <div className="about-con">
              <h4>
                Mint Your Dawgs
              </h4>

              {/*{(Date.now() < publicMintStart) &&
                  <div className="col mt-5 p-5 bg-primary-yellow">

                    <Countdown
                        date={allowlistMintStart}
                        renderer={allowlistCountdownRenderer}
                    />

                  </div>
              }*/}


              <div className="col mt-5 p-5 bg-primary-yellow">

                <p>
                  Mint has been delayed while we built out our utility portal and document our utility.
                </p>

                {/*<Countdown
                    date={publicMintStart}
                    renderer={publicCountdownRenderer}
                />*/}

              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </>
  )
}