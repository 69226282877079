import { Link } from "react-router-dom";

export default function Header() {
  return(
      <header>
        <div className="header-top">
          <div className="container">
            <div className="nav-top">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-6 social-icon">
                  <a href="https://discord.gg/dawgsday" target="_blank" title="Dawg's Day Discord" rel="noopener">
                    <img src="img/icon-top-discord.png" alt="Discord icon" />
                  </a>
                  <a href="https://twitter.com/dawgsdaynft" target="_blank" title="Dawg's Day Twitter" rel="noopener">
                    <img src="img/icon-top-twitter.png" alt="Twitter icon" />
                  </a>
                  <a href="https://opensea.io/collection/dawgs-day" target="_blank" title="Dawg's Day collection on OpenSea" rel="noopener">
                    <img src="img/icon-top-opensea.png" alt="OpenSea icon" />
                  </a>
                </div>

                <div className="col-md-6 col-sm-6 col-6 topBtn">
                  <Link className="mint-btn text-decoration-none" to="/mint">
                    Mint
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="header-sticky-details">
          <div className="header-sticky">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light">
                <Link className="navbar-brand" to="/">
                  <img src="/img/dawgs-day-logo-primary.png" />
                </Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                  <div className="navbar-nav">
                    <a className="nav-item nav-link active" href="/#about">About</a>
                    <a className="nav-item nav-link" href="/#roadmap">Roadmap</a>
                    <a className="nav-item nav-link" href="/#team">Team</a>
                    <a className="nav-item nav-link" href="/#faq">FAQs</a>
                  </div>
                </div>

              </nav>
            </div>
          </div>
        </div>
      </header>
  )
}